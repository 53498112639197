import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const pagos = ref(null);

    const createPagos = async (data) => {
        const res = await axios.post('/care/pagos/', data);
        if (res.status === 201) {
            pagos.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getPago = async (id) => {
        const res = await axios.get(`/care/pagos/${id}`);
        if (res.status === 200) {
            pagos.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updatePago = async (data) => {
        const res = await axios.put(`/care/pagos/${data.id}`, data);
        if (res.status === 200) {
            pagos.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const sendPago = async (id) => {
        const res = await axios.post(`/care/pagos/send/${id}`);
        if (res.status === 200) {
            pagos.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const cancelPago = async (id) => {
        const res = await axios.put(`/care/pagos/cancel/${id}`);
        if (res.status === 200) {
            pagos.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        pagos,
        createPagos,
        getPago,
        updatePago,
        sendPago,
        cancelPago
    };
}
