import { defineAsyncComponent, markRaw } from 'vue';
import ModuleComponents from '@/ModuleComponents';

export default function useColumns() {
    const components = [
        {
            title: 'SimpleStatus',
            load: () => import('@/components/list/components/SimpleStatus.vue'),
        },
        {
            title: 'PagoStatus',
            load: () => import('@/components/list/components/PagoStatus.vue'),
        },
        {
            title: 'DateColumn',
            load: () => import('@/components/list/components/DateColumn.vue'),
        },
        {
            title: 'DateTimeColumn',
            load: () => import('@/components/list/components/DateTimeColumn.vue'),
        },
    ];

    const getComponent = async (title) => {
        try {
            const component = components.find((obj) => obj.title === title);
            if (component) {
                const asyncComponente = defineAsyncComponent(() => component.load());
                return markRaw(asyncComponente);
            }
            const moduleComponents = await ModuleComponents();
            const asyncComponente = defineAsyncComponent(() => {
                const componentLoaded = moduleComponents.getComponent(title).load();
                return componentLoaded;
            });
            return markRaw(asyncComponente);

            // console.log(asyncComponente);
        } catch (error) {
            console.log(error.message);
            return false;
        }
    };

    return { components, getComponent };
}
