import { reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line object-curly-newline
import { required } from '@vuelidate/validators';

export const usePagosForm = () => {
    const state = reactive({
        id: '',
        link: '',
        paciente: '',
        concepto: '',
        productos: [],
        total: 0,
        status: '0',
    });

    const setState = (c) => {
        state.id = c.id;
        state.link = c.link;
        state.paciente = c.paciente.id;
        state.concepto = c.concepto;
        state.productos = c.productos;
        state.total = c.total;
        state.status = c.status;
    };

    const rules = computed(() => ({
        paciente: { required },
        concepto: { required },
        productos: { required },
        total: { required },
        status: { required },
    }));

    const $v = useVuelidate(rules, state);

    return {
        state,
        setState,
        $v,
        rules,
    };
};

export default {};
