<template>
    <span v-if="value === '0'">Borrador</span>
    <span v-if="value === '1'">Activo</span>
    <span v-if="value === '2'">Pagado</span>
    <span v-if="value === '3'">Expirado</span>
</template>

<script>
export default {
    name: 'PagoStatus',
    props: {
        value: { type: String, required: true },
    },
};
</script>

<style></style>
