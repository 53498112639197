<template>
    <select
        class="form-select btn-square digits"
        v-model="value.modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
        <option disable></option>
        <option :value="3">Expirado</option>
        <option :value="2">Pagado</option>
        <option :value="1">Activo</option>
        <option :value="0">Borrador</option>
    </select>
</template>

<script>
import { reactive } from 'vue';

export default {
    props: {
        modelValue: Number,
    },
    setup(props) {
        const value = reactive(props);
        return { value };
    },
};
</script>

<style></style>
