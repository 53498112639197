<template>
    <WindowLayout :title="title">
        <div class="col-sm-12">
            <div class="darmin card">
                <div class="card-header pb-0 d-flex align-items-center">
                    <h5>{{ headerTitle }}</h5>
                </div>
                <PacienteInfo v-model="form.state.paciente" :isReadOnly="!isNew"></PacienteInfo>
                <div class="card-body theme-form">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Link</label>
                                <div class="col-md-9">
                                    <a :href="fullLink">
                                        {{ fullLink }}
                                    </a>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Concepto</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" type="text" v-model="form.state.concepto"
                                        :readonly="form.state.status != '0'" />
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Total a pagar</label>
                                <div class="col-md-9">
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <input class="form-control" type="text"
                                            :value="formatPlainTextCurrency(form.state.total)" readonly />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Estado</label>
                                <div class="col-md-9">
                                    <SelectInputPagoState v-model="form.state.status" disabled />
                                    <!-- <input class="form-control" type="text" v-model="fixedStatus.text" readonly /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="form.state.status != '2'" class="card-footer">
                    <div class="row">
                        <div v-if="!form.state.id" class="col-12 text-end">
                            <div class="btn btn-primary ms-auto" @click="createLinkDePago">
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                        </div>
                        <div v-else class="col-12 text-end">
                            <div class="btn btn-primary ms-auto mx-2" @click="sendLinkDePago">
                                Enviar
                            </div>
                            <div v-if="form.state.status == '0'" class="btn btn-primary ms-auto mx-2"
                                @click="updateLinkDePago">
                                Guardar
                            </div>
                            <div v-if="form.state.status == '1' || form.state.status == '3'"
                                class="btn btn-danger ms-auto" @click="cancelLinkDePago">
                                Anular
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <div class="darmin card">
                        <div class="card-header pb-0 d-flex align-items-center">
                            <h5>Lista de items</h5>
                        </div>
                        <div class="card-body theme-form">
                            <table class="table table-striped mb-3">
                                <thead>
                                    <tr>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Subtotal</th>
                                        <th v-if="form.state.status == '0'" scope="col">...</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in form.state.productos" :key="item">
                                        <td>{{ item.cantidad }}</td>
                                        <td>{{ item.descripcion }}</td>
                                        <td>${{ formatPlainTextCurrency(item.precio) }}</td>
                                        <td v-if="form.state.status == '0'"><i class="fas fa-trash-alt"
                                                @click="deleteProduct(item)" style="cursor: pointer;"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3 row">
                                        <label class="col-md-3 col-form-label">Total a pagar</label>
                                        <div class="col-md-9">
                                            <div class="input-group d-flex w-50">
                                                <span class="input-group-text">$</span>
                                                <input class="form-control text-end" type="text"
                                                    :value="formatPlainTextCurrency(form.state.total)" readonly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="form.state.status == '0'" class="col">
                    <div class="darmin card">
                        <div class="card-header pb-0 d-flex align-items-center">
                            <h5>Añadir items</h5>
                        </div>
                        <div class="card-body theme-form">
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Cantidad</label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" v-model="producto.cantidad"
                                        @keypress="isNumber" />
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Descripción</label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" v-model="producto.descripcion" />
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label class="col-md-3 col-form-label">Precio</label>
                                <div class="col-md-9">
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <input class="form-control" type="text" v-model="producto.precio"
                                            @keypress="isNumber" />
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-end">
                                    <div class="btn btn-primary ms-auto" @click="addProduct">
                                        Agregar
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { defineProps, ref, reactive, onMounted } from 'vue';

import WindowLayout from '@/layouts/Window.layout.vue';

import SelectInputPagoState from '@/components/form/SelectInputPagoState.vue';

import { useSnackbar } from 'vue3-snackbar';

import PacienteInfo from '../Pacientes/components/PacienteInfo.vue';

import usePagos from './services/usePagos';
import { usePagosForm } from './composables/usePagosForm';

const props = defineProps({
    title: { type: String, default: 'Links De Pago' },
    headerTitle: { type: String, default: 'Link De Pago' },
    urlRead: { type: String, required: false },
    urlCreate: { type: String, required: false },
    urlUpdate: { type: String, required: false },
    item: Object,
});

const snackbar = useSnackbar();

const service = usePagos();
const form = usePagosForm();

const fullLink = ref('');

// const fixedStatus = ref(
//     { text: 'Borrador' }
// );

onMounted(async () => {
    if (props.item) {
        await Promise.all([service.getPago(props.item.id)]);
        if (service.pagos) {
            form.setState(service.pagos.value);

            fullLink.value = `${process.env.VUE_APP_CARE_PAGOS_FRONT_URL}${form.state.link}`;
        }
    }
});

const producto = reactive({
    cantidad: '',
    descripcion: '',
    precio: '',
});

const isNumber = (evt) => {
    const event = evt || window.event;
    const charCode = event.which || event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    }

    return true;
};

const formatPlainTextCurrency = (value) => {
    // Verifica si el valor es undefined, NaN o un valor no numérico
    if (value === undefined || value === null || Number.isNaN(value) || value === '') {
        return '0.00';
    }

    // Convertir el valor a un número con dos decimales
    const formattedValue = parseFloat(value).toFixed(2);

    // Agregar comas cada tres dígitos antes del punto decimal
    const [integerPart, decimalPart] = formattedValue.split('.');
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Devolver el número formateado
    return `${integerWithCommas}.${decimalPart}`;
};

const addProduct = () => {
    const cantidad = parseInt(producto.cantidad, 10);
    const precio = parseFloat(producto.precio.replace(/[^0-9.]/g, '')); // Elimina cualquier carácter no numérico, si es necesario

    // Verifica si los valores son válidos
    if (Number.isNaN(cantidad) || Number.isNaN(precio) || !producto.descripcion) {
        snackbar.add({
            type: 'error',
            text: 'Se deben llenar todos los datos del item correctamente.',
        });
        return;
    }

    // Calcular el precio total (cantidad * precio)
    const totalPrecio = cantidad * precio;

    // Verifica que el total calculado no sea NaN
    if (Number.isNaN(totalPrecio)) {
        snackbar.add({
            type: 'error',
            text: 'Error al calcular el precio total.',
        });
        return;
    }

    // Asigna el valor calculado al precio del producto
    producto.precio = totalPrecio;

    // Agregar el producto al formulario
    form.state.productos.push({ ...producto });

    form.state.total = form.state.productos.reduce((total, item) => total + parseFloat(item.precio), 0);

    // Limpiar los campos
    producto.cantidad = '';
    producto.descripcion = '';
    producto.precio = '';
};

const deleteProduct = (product) => {
    const index = form.state.productos.indexOf(product);
    if (index !== -1) {
        form.state.productos.splice(index, 1);
    }
    form.state.total = form.state.productos.reduce((total, item) => total + parseFloat(item.precio), 0);
};

const createLinkDePago = async () => {
    if (!form.state.id) {
        await form.$v.value.$validate();
        if (!form.$v.value.error) {
            await service.createPagos(form.state)
                .then(() => {
                    snackbar.add({
                        type: 'success',
                        text: 'Item ha sido creado',
                    });

                    form.setState(service.pagos.value);

                    fullLink.value = `${process.env.VUE_APP_CARE_PAGOS_FRONT_URL}${form.state.link}`;
                })
                .catch((error) => {
                    console.log(error);
                    error.data.errors.map((e) => {
                        snackbar.add({
                            type: 'error',
                            text: e.msg,
                        });
                        return true;
                    });
                });
        } else {
            console.log(form.$v.value.$errors);
        }
    }
};

const updateLinkDePago = async () => {
    if (form.state.id) {
        await form.$v.value.$validate();
        if (!form.$v.value.error) {
            await service.updatePago(form.state)
                .then(() => {
                    snackbar.add({
                        type: 'success',
                        text: 'Item ha sido actualizado',
                    });

                    form.setState(service.pagos.value);
                })
                .catch((error) => {
                    console.log(error);
                    error.data.errors.map((e) => {
                        snackbar.add({
                            type: 'error',
                            text: e.msg,
                        });
                        return true;
                    });
                });
        } else {
            console.log(form.$v.value.$errors);
        }
    }
};

const sendLinkDePago = async () => {
    if (form.state.id) {
        await service.sendPago(form.state.id)
            .then(() => {
                snackbar.add({
                    type: 'success',
                    text: 'Item ha sido enviado',
                });

                form.setState(service.pagos.value);
            })
            .catch((error) => {
                console.log(error);
                error.data.errors.map((e) => {
                    snackbar.add({
                        type: 'error',
                        text: e.msg,
                    });
                    return true;
                });
            });
    }
};

const cancelLinkDePago = async () => {
    if (form.state.id) {
        await service.cancelPago(form.state.id)
            .then(() => {
                snackbar.add({
                    type: 'success',
                    text: 'Item ha sido anulado',
                });

                form.setState(service.pagos.value);
            })
            .catch((error) => {
                console.log(error);
                error.data.errors.map((e) => {
                    snackbar.add({
                        type: 'error',
                        text: e.msg,
                    });
                    return true;
                });
            });
    }
};
</script>
